import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { BoardMembershipRoles } from '../constants/Enums';
import BoardActions from '../components/BoardActions';

const mapStateToProps = (state) => {
  const allUsers = selectors.selectUsers(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProject(state);
  const memberships = selectors.selectMembershipsForCurrentBoard(state);
  const currentUserAllMemberships = selectors.selectCurrentUserMembershipForAllBoards(state);
  let labels = selectors.selectLabelsForCurrentBoard(state);
  const globalLabels = selectors.selectGlobalLabels(state);
  if (globalLabels && globalLabels.length > 0) labels = labels.concat(globalLabels);
  const filterUsers = selectors.selectFilterUsersForCurrentBoard(state);
  const filterCreators = selectors.selectFilterCreatorsForCurrentboard(state);
  const filterLabels = selectors.selectFilterLabelsForCurrentBoard(state);
  const filterText = selectors.selectFilterTextForCurrentBoard(state);
  const filterArchived = selectors.selectFilterArchivedSateForCurrentBoard(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentBoard(state);

  const isCurrentUserEditor =
    !!currentUserMembership && currentUserMembership.role === BoardMembershipRoles.EDITOR;

  return {
    memberships,
    currentUserAllMemberships,
    labels,
    filterUsers,
    filterCreators,
    filterLabels,
    filterText,
    filterArchived,
    allUsers,
    canEdit: isCurrentUserEditor,
    canEditMemberships: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onMembershipCreate: entryActions.createMembershipInCurrentBoard,
      onMembershipUpdate: entryActions.updateBoardMembership,
      onMembershipDelete: entryActions.deleteBoardMembership,
      onUserToFilterAdd: entryActions.addUserToFilterInCurrentBoard,
      onUserFromFilterRemove: entryActions.removeUserFromFilterInCurrentBoard,
      onLabelToFilterAdd: entryActions.addLabelToFilterInCurrentBoard,
      onLabelFromFilterRemove: entryActions.removeLabelFromFilterInCurrentBoard,
      onLabelCreate: entryActions.createLabelInCurrentBoard,
      onLabelUpdate: entryActions.updateLabel,
      onLabelMove: entryActions.moveLabel,
      onLabelDelete: entryActions.deleteLabel,
      onTextFilterUpdate: entryActions.filterText,
      onTextFilterArchivedUpdate: entryActions.filterArchived,
      onCreatorAdd: entryActions.addCreatorToFilterInCurrentBoard,
      onCreatorRemove: entryActions.removeCreatorFromFilterInCurrentBoard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BoardActions);
