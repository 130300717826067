export const ProjectBackgroundTypes = {
  GRADIENT: 'gradient',
  IMAGE: 'image',
};

export const BoardMembershipRoles = {
  EDITOR: 'editor',
  VIEWER: 'viewer',
};

export const ListSortTypes = {
  NAME_ASC: 'name_asc',
  DUE_DATE_ASC: 'dueDate_asc',
  CREATED_AT_ASC: 'createdAt_asc',
  CREATED_AT_DESC: 'createdAt_desc',
};

export const ActivityTypes = {
  CREATE_CARD: 'createCard',
  MOVE_CARD: 'moveCard',
  ARCHIVE_CARD: 'archiveCard',
  CREATE_ATTACHMENT: 'createAttachment',
  DELETE_ATTACHMENT: 'deleteAttachment',
  UPDATE_ATTACHMENT: 'updateAttachment',
  COMMENT_CARD: 'commentCard',
  MENTION_USER: 'mentionUser',
  ADD_USER_TO_CARD: 'addUserToCard',
  ADD_USER_TO_TASK: 'addUserToTask',
};
