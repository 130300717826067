/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import Editor from '../../Editor';
import { useDidUpdate, useToggle } from '../../../lib/hooks';
import { useClosableForm, useForm } from '../../../hooks';
import styles from './CommentAdd.module.scss';
import User from '../../User';

const DEFAULT_DATA = '';


const CommentAdd = React.forwardRef(({ onCreate, mentionUsers }, ref) => {
  const [t] = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [value, setValue] = useState('');
  const [updatedValue, setUpdatedValue] = useState(DEFAULT_DATA);
  const [isLocked, setIsLocked] = useState(true);

  const editorRef = useRef(null);

  const open = useCallback(() => {
    setIsLocked(false);
    editorRef.current.refocusEditor();
  }, []);

  const close = useCallback(() => {
    setIsLocked(true)
    setUpdatedValue(DEFAULT_DATA);
  }, [])

  const handleEditClick = useCallback((user) => {
    open();
    // editorRef.current.focus();
    editorRef.current.insertReplyClick(user)
  }, [open])

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
      handleEditClick
    }),
    [open, close, handleEditClick],
  );

  const submit = useCallback(() => {
    if (!updatedValue) {
      return;
    }

    onCreate({
      text: updatedValue.markdown,
      slate: updatedValue.slate,
    });

    setUpdatedValue(DEFAULT_DATA);
    setValue('');
    editorRef.current.resetEditor();

    close()
  }, [onCreate, updatedValue, close]);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  return (
    <Form onSubmit={handleSubmit}>
      <div
        onClick={() => {
          if (isLocked) {
            open();
          }
        }}
        className="slateEditor"
      >
        <Editor
          ref={editorRef}
          isLocked={isLocked}
          value={value}
          onChange={setUpdatedValue}
          mentionList={mentionUsers}
          editorPlaceholder="Add a comment"
        />
        <div className={styles.controls}>
          {!isLocked && (
            <>
              <Button positive content='Save Comment' />
              <Button negative content="Discard Changes" onClick={close} />
            </>
          )}
        </div>
      </div>
    </Form>
  );
});

CommentAdd.propTypes = {
  onCreate: PropTypes.func.isRequired,
  mentionUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    display: PropTypes.string,
    profilePicture: PropTypes.string,
  })).isRequired,
};

export default CommentAdd;
