import { createSelector } from 'redux-orm';

import orm from '../orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { isLocalId } from '../utils/local-id';

export const selectCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  ({ Project }, id) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel.ref;
  },
);

export const selectManagersForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .filter((projectManagerModel => {
        if (!projectManagerModel.user) {
          if (!projectManagerModel?.ref?.userId) return false;

          const user = User.withId(projectManagerModel.ref.userId);
          if (!user) return false;
        }

        return true;
      }))
      .map((projectManagerModel) => {
        let user = projectManagerModel.user?.ref;

        if (!projectManagerModel.user)
          user = User.withId(projectManagerModel.ref.userId);

        return {
          ...projectManagerModel.ref,
          isPersisted: !isLocalId(projectManagerModel.id),
          user: {
            ...user,
            isCurrent: user.id === currentUserId,
          }
        };
      });
  },
);

export const selectMembersForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project, User }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedMembersQuerySet()
      .toModelArray()
      .filter((projectMemberModel => {
        if (!projectMemberModel.user) {
          if (!projectMemberModel?.ref?.userId) return false;

          const user = User.withId(projectMemberModel.ref.userId);
          if (!user) return false;
        }

        return true;
      }))
      .map((projectMemberModel) => {
        let user = projectMemberModel.user?.ref;

        if (!projectMemberModel.user)
          user = User.withId(projectMemberModel.ref.userId);

        return {
          ...projectMemberModel.ref,
          isPersisted: !isLocalId(projectMemberModel.id),
          user: {
            ...user,
            isCurrent: user.id === currentUserId,
          },
        };
      });
  },
);

export const selectBoardsForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedBoardsModelArrayAvailableForUser(currentUserId)
      .map((boardModel) => ({
        ...boardModel.ref,
        isPersisted: !isLocalId(boardModel.id),
      }));
  },
);

export const selectIsCurrentUserManagerForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return false;
    }

    return projectModel.hasManagerForUser(currentUserId);
  },
);

export const selectIsCurrentUserMemberForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return false;
    }

    return projectModel.hasMemberForUser(currentUserId);
  },
);

export default {
  selectCurrentProject,
  selectManagersForCurrentProject,
  selectMembersForCurrentProject,
  selectBoardsForCurrentProject,
  selectIsCurrentUserManagerForCurrentProject,
  selectIsCurrentUserMemberForCurrentProject,
};
