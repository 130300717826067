import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header, Modal, Dropdown } from 'semantic-ui-react';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useForm } from '../../hooks';
import styles from './GlobalLabel.module.scss';

import LabelsStep from '../LabelsStep';

const GlobalLabelModal = React.memo(
  ({
    allGlobalLabels,
    currentUserAllMemberships,
    onGlobalLabelUpdate,
    onLabelCreate,
    onLabelDelete,
    onLabelUpdate,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [path, handleFieldChange] = useForm(() => ({
      globalLabelId: null,
    }));

    const filteredGlobalLabels = useMemo(
      () => allGlobalLabels.filter((label) => label.boardId),
      [allGlobalLabels]
    );

    const selectedGlobalLabels = useMemo(
      () => filteredGlobalLabels.find((label) => label.id === path.globalLabelId) || null,
      [filteredGlobalLabels, path.globalLabelId]
    );

    const handleSubmit = useCallback(() => {
      const l = filteredGlobalLabels.find((label) => label.id === path.globalLabelId);
      if (l) {
        l.autoSyncLabel = true;
        onGlobalLabelUpdate(l.id, l);
      }
    }, [onGlobalLabelUpdate, path, filteredGlobalLabels]);

    const handleGlobalLabelChange = useCallback(
      (event, data) => {
        handleFieldChange(event, data);
      },
      [handleFieldChange]
    );

    useEffect(() => {
      const l = filteredGlobalLabels.find((label) => label.autoSyncLabel === true);
      if (l) {
        handleFieldChange(null, { name: 'globalLabelId', value: l.id });
      }
    }, [filteredGlobalLabels, handleFieldChange]);

    return (
      <Modal open closeIcon size="large" onClose={onClose}>
        <Header inverted size="huge">
          Global Labels
        </Header>
        <Modal.Content>
          <div>
            <Form onSubmit={handleSubmit}>
              <h4>Select Auto Sync Label</h4>
              <Dropdown
                fluid
                selection
                name="globalLabelId"
                options={filteredGlobalLabels.map((label) => ({
                  text: label.name,
                  value: label.id,
                }))}
                value={selectedGlobalLabels && selectedGlobalLabels.id}
                placeholder={
                  filteredGlobalLabels.length === 0 ? 'No global Labels' : t('')
                }
                disabled={filteredGlobalLabels.length === 0}
                className={styles.field}
                onChange={handleGlobalLabelChange}
              />
              <Button positive content="save" />
            </Form>
          </div>
          <div>
            <LabelsStep
              canEdit
              items={allGlobalLabels}
              currentUserAllMemberships={currentUserAllMemberships}
              currentIds={[]}
              onCreate={onLabelCreate}
              onUpdate={onLabelUpdate}
              onDelete={onLabelDelete}
              onSelect={() => { }}
              onDeselect={() => { }}
              onMove={() => { }}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
);

GlobalLabelModal.propTypes = {
  // defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // isSubmitting: PropTypes.bool.isRequired,
  // onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  allGlobalLabels: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  currentUserAllMemberships: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onLabelCreate: PropTypes.func.isRequired,
  onLabelUpdate: PropTypes.func.isRequired,
  onLabelDelete: PropTypes.func.isRequired,
  onGlobalLabelUpdate: PropTypes.func.isRequired,
};

export default GlobalLabelModal;
