import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup as SemanticUIPopup } from 'semantic-ui-react';

import styles from './PopupHeader.module.css';

const PopupHeader = React.memo(({ children, onBack, height }) => (
  /* eslint-disable prefer-template */
  <SemanticUIPopup.Header className={styles.wrapper} style={{ height }}>
    {onBack && <Button icon="angle left" onClick={onBack} className={styles.backButton} />}
    <div className={styles.content}>{children}</div>
  </SemanticUIPopup.Header>
  /* eslint-enable prefer-template */
));

PopupHeader.propTypes = {
  children: PropTypes.node.isRequired,
  onBack: PropTypes.func,
  height: PropTypes.string,
};

PopupHeader.defaultProps = {
  onBack: undefined,
  height: '40px',
};

export default PopupHeader;
