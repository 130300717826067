import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { BoardMembershipRoles } from '../constants/Enums';
import Card from '../components/Card';

const makeMapStateToProps = () => {
  const selectCardById = selectors.makeSelectCardById();
  const selectUsersByCardId = selectors.makeSelectUsersByCardId();
  const selectLabelsByCardId = selectors.makeSelectLabelsByCardId();
  const selectGlobalLabelsByCardId = selectors.makeSelectGlobalLabelsByCardId();
  const selectTasksByCardId = selectors.makeSelectTasksByCardId();
  const selectNotificationsTotalByCardId = selectors.makeSelectNotificationsTotalByCardId();
  const selectTaskGroupFromTaskGroupIds = selectors.selectTaskGroupFromTaskGroupIds();

  return (state, { id, index }) => {
    const { projectId } = selectors.selectPath(state);
    const allProjectsToLists = selectors.selectProjectsToListsForCurrentUser(state);
    const allBoardMemberships = selectors.selectMembershipsForCurrentBoard(state);
    let allLabels = selectors.selectLabelsForCurrentBoard(state);
    const allGlobalLabels = selectors.selectGlobalLabels(state);
    allLabels = allLabels.concat(allGlobalLabels);

    const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentBoard(state);
    const currentUserAllMemberships = selectors.selectCurrentUserMembershipForAllBoards(state);

    const {
      name,
      dueDate,
      isDueDateCompleted,
      stopwatch,
      coverUrl,
      boardId,
      listId,
      isPersisted,
      storyPoints,
      archived,
      commentsCount,
    } = selectCardById(state, id);

    const users = selectUsersByCardId(state, id);
    let labels = selectLabelsByCardId(state, id);
    const globalLabels = selectGlobalLabelsByCardId(state, id);
    if (globalLabels && globalLabels.length > 0) labels = labels.concat(globalLabels);
    const tasks = selectTasksByCardId(state, id);
    const groupIds = tasks.map((task) => task.groupId);
    const uniqueGroupIds = [...new Set(groupIds)];

    const groups = selectTaskGroupFromTaskGroupIds(state, uniqueGroupIds);

    const taskGroups = uniqueGroupIds
      .map((groupId) => {
        const tasksInGroup = tasks.filter((task) => task.groupId === groupId);
        const g = groups.find((group) => Number(group.id) === Number(groupId));
        if (!g) return false;
        return {
          id: groupId,
          tasks: tasksInGroup,
          name: g.name ? g.name : 'Task',
        };
      })
      .filter(Boolean);

    const notificationsTotal = selectNotificationsTotalByCardId(state, id);

    const isCurrentUserEditor =
      !!currentUserMembership && currentUserMembership.role === BoardMembershipRoles.EDITOR;

    return {
      id,
      index,
      name,
      storyPoints,
      dueDate,
      isDueDateCompleted,
      stopwatch,
      coverUrl,
      boardId,
      listId,
      projectId,
      isPersisted,
      notificationsTotal,
      users,
      labels,
      tasks,
      allProjectsToLists,
      allBoardMemberships,
      allLabels,
      archived,
      canEdit: isCurrentUserEditor,
      commentsCount,
      taskGroups,
      currentUserAllMemberships,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateCard(id, data),
      onMove: (listId, index) => entryActions.moveCard(id, listId, index),
      onTransfer: (boardId, listId) => entryActions.transferCard(id, boardId, listId),
      onDuplicate: () => entryActions.duplicateCard(id),
      onDelete: () => entryActions.deleteCard(id),
      onUserAdd: (userId) => entryActions.addUserToCard(userId, id),
      onUserRemove: (userId) => entryActions.removeUserFromCard(userId, id),
      onBoardFetch: entryActions.fetchBoard,
      onLabelAdd: (labelId) => entryActions.addLabelToCard(labelId, id),
      onLabelRemove: (labelId) => entryActions.removeLabelFromCard(labelId, id),
      onLabelCreate: (data) => entryActions.createLabelInCurrentBoard(data),
      onLabelUpdate: (labelId, data) => entryActions.updateLabel(labelId, data),
      onLabelMove: (labelId, index) => entryActions.moveLabel(labelId, index),
      onLabelDelete: (labelId) => entryActions.deleteLabel(labelId),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Card);
